import { TypographyProps } from "@mui/material"
import Link from "@mui/material/Link/Link"
import Typography from "@mui/material/Typography"
import { FC } from "react"
import { Link as RouterLink } from 'react-router-dom'
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { link } from "src/common/routing/routes"
import { useEvent } from "src/events/event-context"


export type LocationNameProps = Partial<TypographyProps> & {
    locationId: string | null
    prefix?: string
    noLink?: boolean
}

export const LocationName: FC<LocationNameProps> = ({
    locationId,
    prefix,
    noLink,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const locationQuery = backend.locations.useGetLocations([client.id, event.id], {
        select: locations => locations.find(location => location.id === locationId),
    })

    if (!locationId) {
        return null
    }

    const name = locationQuery.data?.name ?? ""

    return <Typography component="span" {...props}>
        {prefix}
        {noLink ? name : <Link
            children={name}
            component={RouterLink}
            to={noLink ? "" : link.toLocation(client.id, event.id, locationId)}
            underline="hover"
            color="inherit"
        />}
    </Typography>

}