import { Scope } from "@marketpartner/backend-api";
import { Collapse, DialogTitle, Grid } from "@mui/material";
import { FC, useState } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { DialogForm } from "src/common/dialogs/DialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { NumberTextField } from "src/common/form-inputs/NumberTextField";
import { withNotification } from "src/common/notifications/with-notification";
import { EmailTemplateSelector } from "src/email/EmailTemplateSelector";
import { useEvent } from "src/events/event-context";


export type EditMessagingConfigurationDialogProps = LockableDialogProps

const EditMessagingConfigurationDialog: FC<EditMessagingConfigurationDialogProps> = ({
    ...props
}) => {
    return <LockableDialog
        {...props}
    >
        <DialogTitle>Messaging configuration</DialogTitle>
        <MessagingConfigurationForm />
    </LockableDialog>
}

export const MessagingConfigurationForm: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const [notificationTemplateId, setNotificationTemplateId] = useState(event.mailjetTemplates.messageNotification)
    const [notificationGracePeriod, setNotificationGracePeriod] = useState(event.messageNotificationEmailGracePeriodMinutes)
    const [notificationMaxFrequency, setNotificationMaxFrequency] = useState(event.messageNotificationEmailMaxFrequencyMinutes)

    const updateEventMutation = backend.events.useUpdateEvent(withNotification({
        errorMessage: "Error updating messaging configuration"
    }))

    return <DialogForm
        actionName="Save"
        formData={{}}
        onSubmit={() => updateEventMutation.mutateAsync([client.id, event.id, {
            mailjetTemplates: {
                messageNotification: notificationTemplateId,
            },
            messageNotificationEmailGracePeriodMinutes: notificationGracePeriod,
            messageNotificationEmailMaxFrequencyMinutes: notificationMaxFrequency,
        }])}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <EmailTemplateSelector
                    label="Notification email template"
                    selectedId={notificationTemplateId}
                    onSelectId={setNotificationTemplateId}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Collapse in={Boolean(notificationTemplateId)}>
                    <NumberTextField
                        label="Notification email grace period (minutes)"
                        value={notificationGracePeriod}
                        onChange={setNotificationGracePeriod}
                        helperText="Wait this long before sending an email to allow messages to be read"
                        fullWidth
                    />
                </Collapse>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Collapse in={Boolean(notificationTemplateId)}>
                    <NumberTextField
                        label="Maximum notification email frequency (minutes)"
                        value={notificationMaxFrequency}
                        onChange={setNotificationMaxFrequency}
                        helperText="Do not send notification emails more frequently than this"
                        fullWidth
                    />
                </Collapse>
            </Grid>
        </Grid>
    </DialogForm>
}

export const useEditMessagingConfigurationDialog = createPopupHook({
    element: EditMessagingConfigurationDialog,
    scope: Scope.Event,
})